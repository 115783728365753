.ArticlesDetails{
    background-color: #F0FAFF;
}

.articleDetailsContainer{
    padding: 100px 0 ;
    font-family: Poppins;
    opacity: 1;
}
.articleDetailsContainer h1{
    font: normal normal 600 40px/42px;
    color: #3C3C3C;
}
.articleDetailsContainer p span{
    color:#0383C8 ;
}
.articleDetailsContainer h3{
    text-align: left;
    font: normal normal normal 21px/30px ;
    color: #707070;
    margin-top: 90px;
}
.articleDetailsContentDescription ul li{
    list-style-type: none;
    font: normal normal normal 23px/34px Poppins;
    color: #707070;
    padding: 20px 0;
}
.articleDetailsContentDescription ul{
    padding: 0;
    margin: 0;
}
.articleDetailsContentDescription{
    padding: 0;
}
.articleDetailsRelatedata{
    background-color: #F0FAFF;
}
.articleDetailsContainerImg .SiderImg img{
    padding-top: 100px;
    height: 490px;
    width: 100%;
}
@media(max-width:1024px){
    .articleDetailsContainer h1{
        font-size: 32px;
    }
    .articleDetailsContainer h3{
        font-size: 20px;
        margin-top:60px;
    }
    .articleDetailsContainerImg .SiderImg img {
        padding-top: 100px;
        height: 450px;
        width: 100%;
    }
    .articleDetailsContentDescription ul li{
        font-size: 18px;
    }
}
@media(max-width:767px){
    .articleDetailsContainer h1{
        font-size: 24px;
    }
    .articleDetailsContainer h3{
        font-size: 18px;
        margin-top:60px;
    }
    .articleDetailsContainerImg .SiderImg img {
        padding-top: 0px;
        height: 280px;
        width: 100%;
    }
    .articleDetailsContainer {
        padding: 50px 30px;
    }
    .ArticlesDetails{
        padding-bottom: 50px;
    }
    .articleDetailsContentDescription ul li{
        font-size: 16px;
        padding: 20px 30px;
    }
}