.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn:hover {
    color: none; 
    background-color: none;
     border-color: none;
}

.main-body {
    padding: 0;
    padding-top: 190px;
}
.backgroundright-image{
    background-size: 230px;
     }
     .backgroundleft-image{
  background-size: 230px;
      }
@media(max-width:550px){
    .main-body{
        padding-top: 130px;
}
}
.banner-unique {
    background-image: url(../../images/bannerline.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 0;
    background-position-y: 26%;
}