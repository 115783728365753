.articleDetailsSlider{
    background-color: #F0FAFF;
    padding-top: 50px;
    height: 500px;
}
.articleDetailsSlider .slick-slide img {
    display: block;
    height: 180px;
    width:100%;
}
.DetailsSliderdata p {
    font-family: Poppins;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
    padding-left: 15px;
    padding-top: 15px;
}
.DetailsSliderdata {
    background-color: #fff;
    width: 90% !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.articleDetailsSlider .slick-track {
    padding-left: 17px;
}
@media(max-width:1024px){

}
@media(max-width:767px){
    
}