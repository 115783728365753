.responsiveFeatures {
    display: none;
}

#container-content-black-background-center {
    margin-top: 70px;
    animation: zoom-in-zoom-out 8s ease infinite;
}

#container-content-noncenter {
    margin-bottom: 70px;
}

.container-content {
    background: #FFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #0000001F;
    border-radius: 20px;
    opacity: 1;
    transition: all .5s ease-in-out;
    animation: zoom-in-zoom-out 6s ease infinite;
}

.container-content-black-background {
    background: #2D2D2D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 50px #0000001F;
    border-radius: 20px;
    opacity: 1;
    transition: all .5s ease-in-out;
    animation: zoom-in-zoom-out 10s ease infinite;
    /* margin-top: 100px; */
}

.mainCapabilties:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

.container-content-black-background p {
    color: #fff;

}

.main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-bottom: 50px;
}

.imgtext {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 20px/30px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal 600 20px/30px Inter;
    letter-spacing: 0px;
    font-size: 18px;
    color: #3C3E3E;
    opacity: 1;
    padding: 25px;
}

.imgsrc {
    width: 100%;
    height: 175px;
    border-radius: 20px;
    opacity: 1;
    margin-top: 15px;
}

.mainCapabilties .container-fluid {
    background-image: url(../../images/bg.jpg);
    background-repeat: no-repeat;
    background-size: 100% 1500px;

    background-position-y: 100px;
}

.container-content:hover {
    scale: 1.1;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1, 1.1);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fade-out {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1);
    }
}


.container-content-black-background:hover {
    scale: 1.1;
    animation-play-state: paused;
}

#container-content-black-background-center:hover {
    scale: 1.1;
    animation-play-state: paused;
}

#capabilities {
    text-align: center;
    margin-bottom: 20px;
}

#capabilities h1 {
    font: normal normal bold 56px/85px inter;

    color: #323232;
    opacity: 1;
    margin-top: 50px;
}


.conatiner-p {

    font: normal normal normal 24px/35px inter;

    color: #626262;
    opacity: 1;
    margin-bottom: 100px;

}

.main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
    margin-bottom: 50px;
}


@media screen and (min-width: 576px) {
    .main {
        grid-template-columns: repeat(2, 1fr);
    }
 
}

@media screen and (min-width: 992px) {
    .main {
        grid-template-columns: repeat(3, 1fr);
    }

}

/* @media screen and (max-width: 992px) {
    #container-content-black-background-center {
        margin-top: 0;
    }

    #container-content-noncenter {
        margin-bottom: 0;
    }
} */
@media(max-width:768px){
    #container-content-noncenter{
        animation:none ;
    }
    #container-content-black-background-center{
        animation: none;
    }

#container-content-black-background-center {
    margin-top: 0px;
}

#container-content-noncenter {
    margin-bottom: 0px;
}
}
@media screen and (max-width: 767px) {
    .main {
        gap: 2rem;
    }
}

@media screen and (max-width: 425px) {

    .responsiveFeatures {
        display: block;
    }

    .mainCapabilties {
        display: none;
    }

    .responsiveFeatures .container {
        padding-left: 0px !important;
    }

    #container-content-black-background-center {

        animation: none;
    }


    .r-black-background {
        background: #2D2D2D 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 50px #0000001F;
        border-radius: 20px;
        opacity: 1;
        color: white !important;
    }

    .r-black-background p {
        color: white;
    }

    .r-content {
        background: #FFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 30px #0000001F;
        border-radius: 20px;
        opacity: 1;
        width: 100%;
        margin: 0 auto;
    }

    .imgtext {
        font: normal normal 600 22px/30px Inter;
    }




    .container-content:hover {
        display: none;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: none;
        }

        25% {
            transform: none;
        }

        50% {
            transform: none;
        }

        75% {
            transform: none;
        }

        100% {
            transform: none;
        }
    }

    @keyframes fade-out {
        from {
            transform: none;
        }

        to {
            transform: none;
        }
    }


    #capabilities {
        text-align: center;
        margin-bottom: 20px;
    }

    #capabilities h1 {

        font: normal normal bold 40px/49px Inter;
        color: #323232;
        opacity: 1;
        margin-top: 50px;
    }


    .conatiner-p {

        font: normal normal normal 18px/21px Inter;
        color: #626262;
        opacity: 1;
        margin-bottom: 100px;

    }

    .main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5rem;
        margin-bottom: 50px;
    }
}
@media screen and (max-width:319px){
    .main{
        padding-left: 10px;
    }
    .imgsrc{
        width:100%;
    }
    .imgtext {
        font: normal normal 600 12px/22px Inter;
    }
    .r-content{
        height:286px;
        margin-top: -60px;
    }
    .r-black-background {
      
        margin-top: -60px;
    }
}
.pause-animation {
    animation-play-state: paused !important;
}