.responsiveSlider {
    display: none;
}
#module a{
text-decoration: none;
}
.slick-slide.slick-active .module-containt {
    /* margin: 0 auto; */
    /* margin-right: auto; */
    margin-bottom: 0px;
    /* display: ; */
    width: 80% !important;
    display: block !important;
    margin: 0 auto;
    /* height: 100% */
}

/* .row{
    display: flex;
    padding-left: 50px;
    padding-right: 33px;
    padding-top: 10px;
    padding-bottom: 34; */
/* --bs-gutter-x: 5.5rem;
    } */

.tittle {

    padding-top: 96px;
}

.leftBtn {
    border: none;
    background: none;
    font-size: 40px;
    position: relative;
    bottom: 416px;
    left: 144px;
    z-index: 110;


}

.rightBtn {
    border: none;
    background: none;
    font-size: 40px;
    position: relative;
    bottom: 416px;
    left: 88%;
    z-index: 110;

}

.slick-arrow {
    background-color: transparent !important;
    opacity: 0.7;
}


.slick-slide.slick-cloned .module-containt,
.slick-slide .module-containt {
    background-color: white;
    border-radius: 20px;
    /* height: 100; */
    box-shadow: 2px 4px 11px #0000001F;
    /* margin-top: 75px; */

}

.slick-slide.slick-cloned .module-containt,
.slick-slide .module-containt video,
.slick-slide .module-containt .module-details {
    display: block;


}


.slick-slide.slick-active .module-containt {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 50px #0000001F; */
    border-radius: 20px;
    opacity: 1;
    height: 100%;
    background-color: white;
    /* padding-top: 30px; */



}



/* .slick-slide.slick-active .module-containt .module-details {
  padding-left: 60px;

}  */

.video-container {
    /* height: 100%; */
    /* position: relative; */
    margin: 0px;
    box-sizing: border-box;
}

.ps-md-5 {
    padding-left: 7rem !important;
}

#module {

    background: #F5F7FA 0% 0% no-repeat padding-box;
    padding: 0px;
    /* height: 980px; */
}

#module li {
    list-style-image: url(../../images/chevron-right-solid.svg);
    padding: 10px 0px 6px 10px;
}

.slick-track {
    position: relative;
    left: 0;
    padding-top: 10px;
    padding-bottom: 70px;
    top: 50px;
}

.modal-content {
    background: none;
    border: none;
}

.model-body {
    width: 70%;
    background-color: #382F7C;
}

video[poster] {
    background-color: transparent;
    padding: 0px;
    margin: 0px;

}

.popVideo {
    width: 100%;
}

button,
select {

    text-transform: none;

}

button,
input,
optgroup,
select,
textarea {

    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

}

button {

    border-radius: 10px;

}

*,
::after,
::before {

    box-sizing: border-box;

}



#module ul {
    list-style-position: outside;
}

#module h2 {
    position: relative;
    font: normal normal bold 56px/85px inter;
    text-align: center;
    color: #323232;
    margin-bottom: 10px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal bold 56px/68px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-323232);

    font: normal normal bold 56px/68px Inter;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
}

#module h6 {
    font: normal normal normal 24px/35px inter;
    color: #626262;
    opacity: 1;
    text-align: center;
    margin-bottom: 0px;
}

#module .playbutton {
    position: absolute;
    top: 40%;
    left: 50%;
    /* bottom: 0; */
    transform: translate(-50%, -50%);
    /* right: 0; */
    margin: auto;
    z-index: 0;
    font-size: 60px;
    color: #382F7C;
}


#module .playbuttonright {
    position: absolute;
    top: 40%;
    left: 50%;
    /* bottom: 0; */
    transform: translate(-50%, -50%);
    /* right: 0; */
    margin: auto;
    z-index: 0;
    font-size: 60px;
    color: #382F7C;
}

#module video {
    /* height: 90%;
    width: 95%; */
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 15px;
    /* width: 100%; */
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;


    text-align: center;
}


.btn-close {
    opacity: 1;
    position: absolute;
    top: 2rem;
    right: 4rem;
    z-index: 100;
}

#module h3 {
    font: normal normal bold 56px/85px inter;
    margin-left: 0.65rem;
    color: #323232;
}

#module p {
    font: normal normal normal 20px/30px inter;
    margin-left: 0.65rem;
    color: #707070;
    opacity: 1;
}

.slick-dots {
    position: relative;
    bottom: 0;
    top: 0px;
}

.slick-dots .slick-active {
    position: relative;
    background-color: inherit !important;
}

.slick-dots li {
    width: unset !important;
}

.slick-dots ul {
    padding-bottom: 2rem !important;
    padding-top: 1rem !important;
    position: relative;
    right: 1rem;

}

.slick-dots .slick-active::after {

    position: absolute;
    bottom: -30px;
    content: "";
    width: 28px;
    height: 5px;
    border-radius: 4px;
    background-color: rgb(0, 183, 255);
    left: 52%;
    transition: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
}





#module .module-details ul {
    /* font: normal normal normal 18px/24px inter; */

    color: #707070;
    opacity: 1;
    text-align: left;
    display: inline-block;
}

/* .module-details .ps-md-5 {
    border-radius: 10px;
    padding-top: 28px;
    padding-left: 50px;
    
    } */

#module .module-details .fa-chevron-right {
    font-size: 12px;

}
.ps-4 {
    padding-left: 0rem!important;
}


/* #module video:hover {
    transition: 0.3s;
    transform: translate(0, -4px);
    } */
.slider {
    min-height: 475px;
    align-items: center;
}

@media(max-width:1700px) {
    #module video {
        width: 100%;
    }
    .slick-dots li {
        margin: 0px -12px;
    }
    .slick-dots{
        width:100%;
        
       
    }
}

@media(max-width:1440px) {
    /* .col-lg-6 {
        flex: 0 0 auto;
        max-width: 37%;
    } */

    .rightBtn {
        left: 86%;
        font-size: 32px;
        bottom: 392px;

    }

    .leftBtn {

        font-size: 30px;

        bottom: 392px;
        left: 9%;

    }

    .ps-md-5 {
        padding-left: 5rem !important;
    }

    #module p {
        font: normal normal normal 24px/36px inter;
    }

    #module .module-details ul {
        font: normal normal normal 18px/18px inter;
    }

    .slick-dots li {

        margin: 0px -28px;

    }
 
    .slick-active video[poster] {
        margin: -16px !important;
    }

    .slick-cloned video[poster]{
        margin: -74px;
    }
    .slick-slide video[poster]{
        margin: -74px;
    }

    #module video {
        width: 100%;
    }
}

@media(max-width:1199px) {

    #module .module-containt {
        justify-content: center !important;
    }
}

@media (max-width:1200px) {
    .slick-active .ps-md-5 {
        padding-left: 3rem !important ;
    }

    #module h3 {
        font: normal normal bold 44px/64px inter;

    }

    #module p {
        font: normal normal normal 18px/20px inter;

    }
    #module .module-details ul {
        font: normal normal normal 16px/22px inter;
    }

    .rightBtn {
        left: 83%;
        font-size: 32px;
        bottom: 392px;

    }

    .leftBtn {

        font-size: 30px;

        bottom: 392px;
        left: 11%;

    }

    #module video {
        width: 100%;
    }
    .slick-dots li {
        margin: 0px -58px;
    }
    .slick-dots{
        width:80%;
        margin-left: 100px;
    }
}

@media(max-width:991px) {
    .rightBtn {
        left: 82%;
        font-size: 32px;
        bottom: 590px;

    }

    video {
        display: block;
    }

    .leftBtn {

        font-size: 30px;

        bottom: 590px;
        left: 12%;

    }

    #module li {
        padding: 10px 0px 0px 0px;
    }

    .slick-dots li {
        margin: -8px -52px;
    }

    .slick-dots {
        left: 18px;
    }

    /* .slick-slide .video-container {
        display: block;
        width: 90%;
    }

    .slick-slide.slick-active .video-container {
        display: block;
        width: 100%;
    } */
    .slick-slide video[poster] {
        margin: 0px;
        width:80% !important;
    }
    .slick-active video[poster] {
        width:100% !important;
    }
    .slick-slide .module-containt{
        padding-left: 70px;
    }
    .slick-slide.slick-active .module-containt{
        padding-left: 0;
    }



    #module video {
        width: 100%;
        position: relative;
        left: 18px;
    }
    .slick-slide.slick-active .video-container {
        display: block;
        width: 100%;
        padding: 30px;
    }
    .slick-slide.slick-active .module-containt .module-details{
        display: block;
    }
    
    /* .slick-slide .module-containt .module-details {
        display: none;
    }
    .slick-slide .video-container {
        display:none;
    } */
    .slider {
        min-height: 660px;
    }











}

@media(max-width:900px) {
    .rightBtn {
        left: 80%;
        font-size: 32px;
        bottom: 560px;

    }

    .leftBtn {

        font-size: 30px;

        bottom: 560px;
        left: 13%;

    }

    .slick-dots li {
        margin: -8px -86px;
    }

    .slick-dots {
        left: 55px;
    }
}

@media(max-width:900px) {
    #module .container-fluid {
        justify-content: center !important;
        margin: auto;
    }

    .module-containt {
        height: 100%;
    }

    .slick-slide.slick-active .module-containt {

        height: 100%;
    }

   

    #module video {

        width: 100%;
    }


    .ps-md-5 {
        padding-left: 1rem !important;
    }


    #module h2 {
        font-size: 50px;
    }

    #module h3 {
        font-size: 35px;
        text-align: left;
    }

    #module h6 {
        font-size: 26px;
    }

    #module p {
        text-align: left;
    }

    #module .module-details ul {
        font: normal normal normal 14px/20px inter
    }

    #module li {
        padding: 17px 2px 0px 43px;
    }

}

@media(max-width:820px) {
    .rightBtn {
        left: 78.5%;
        font-size: 32px;
        bottom: 482px;

    }

    .leftBtn {
        font-size: 30px;
        bottom: 482px;
        left: 13%;
    }
    #module {
        overflow:hidden;
    }
}
@media(max-width:768px){
   
    #module h2 {
        font-size: 50px;
    }
    #module h6{
      font-size: 26px;  
    }
    #module li {
        padding: 17px 0px 0px 28px;
    }
    .slick-dots ul{
        font-size:12px;
    }
    .slick-dots li {
        margin: 0px -84px;
    }
    .slick-dots {
        width: 74%;
        margin-left: 84px;
        left:90px;
    }
    .slick-dots .slick-active::after{
        left:27%;
    }
   
    #module video {
        width: 100%;
        position: relative;
        left: 18px;
    }
    .slick-slide.slick-active .video-container {
        display: block;
        width: 100%;
        padding: 30px;
    }
    .slick-slide.slick-active .module-containt .module-details{
        display: block;
    }
    
    /* .slick-slide .module-containt .module-details {
        display: none;
    }
    .slick-slide .video-container {
        display:none;
    } */
    .slider {
        min-height: 660px;
    }
     /* .slick-active video[poster]{
        width:80%;
    } */
    /* #module video {
        width: 80%;
    } */
}
@media(max-width:730px) {
    .rightBtn {
        left: 76%;
        font-size: 30px;
        bottom: 560px;

    }

    .leftBtn {

        font-size: 30px;

        bottom: 560px;
        left: 14%;

    }
}

@media(max-width:630px) {
    .rightBtn {
        left: 74%;
        font-size: 30px;
        bottom: 560px;

    }

    .leftBtn {

        font-size: 30px;

        bottom: 560px;
        left: 15%;

    }
}

@media(max-width:550px) {
    .responsiveSlider{
        margin-bottom: 60px;
    }
    .rightBtn {
        left: 73%;
        font-size: 30px;
        bottom: 560px;

    }

    .leftBtn {

        font-size: 30px;

        bottom: 560px;
        left: 15%;

    }

    .moduleList {
        width: 150px;
    }
}

@media(max-width:549px) {
    #module .container-fluid {
        justify-content: center !important;
        margin: 0 !important;
    }

    .ps-md-5 {
        padding-left: 0rem !important;
    }

    .slick-slide.slick-active .module-containt {
        margin: 0px;
        padding: 0px;
        width: 100% !important;
        padding: unset;
        margin: unset;
    }

    #module h2 {
        font-size: 30px;
    }

    #module h3 {
        text-align: left;
        font: normal normal bold 40px/49px Inter;
        padding-left: 10px;
        padding-top: 15px;
    }

    #module h6 {
        /* font-size: 16px;    */
        font: normal normal normal 14px/22px Inter;
    }

    #module p {
        text-align: left;
        font: normal normal normal 22px/24px Inter;
        padding-left: 10px;

    }

    #module .module-details ul {
        font: normal normal normal 14px/24px Inter;
        overflow-x: hidden;
    }

    .modal-dialog {
        max-width: 70%;
        margin: 1.75rem auto;
    }

    .module-containt {
        min-height: 700px;
        width: 100%;
    }


    video[poster] {
        overflow: hidden;

    }

    .slider {
        display: none;
    }

    .sbtn {
        display: none
    }

    .responsiveSlider {

        display: block !important;

        border-radius: 20px;
        opacity: 1;
    }

    #module .module-details ul {
        text-align: left;
        font: normal normal normal 16px/24px Inter;
    }


    video[poster] {
        width: 100%;
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 100% !important;
    }

    #module {
        background-color: #FFFFFF;
    }

    #module .module-containt {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 50px #0000001F;
        margin-top: 20px !important;
        border-radius: 20px;
    }
    #module video {
        left: 0px;
    }

}

/* @media(max-width:375px) {
    #module .container-fluid {
        justify-content: center !important;
        margin: 0 !important;
    }

    .ps-md-5 {
        padding-left: 0rem !important;
    }

    .slick-slide.slick-active .module-containt {
        margin: 0px;
        padding: 0px;
        width: 100% !important;
        padding: unset;
        margin: unset;
    }

    #module h2 {
        font-size: 35px;
    }

    #module h3 {
        text-align: left;
        font: normal normal bold 40px/49px Inter;
        padding-left: 10px;
        padding-top: 15px;
    }

    #module h6 {
        font-size: 16px;
    }

    #module p {
        text-align: left;
        font: normal normal normal 22px/24px Inter;
        padding-left: 10px;

    }

    #module .module-details ul {
        font: normal normal normal 14px/24px Inter;
        overflow-x: hidden;
    }

    .modal-dialog {
        max-width: 70%;
        margin: 1.75rem auto;
    }

    .module-containt {
        min-height: 475px;
        width: 100%;
    }


    video[poster] {
        overflow: hidden;
    }

}

@media(max-width:375px) {
    .slider {
        display: none;
    }

    .sbtn {
        display: none
    }

    .responsiveSlider {

        display: block !important;

        border-radius: 20px;
        opacity: 1;
    }

    #module .module-details ul {
        text-align: left;
        font: normal normal normal 16px/24px Inter;
    }

    #module h6 {
        font: normal normal normal 24px/29px Inter;
        color: #707070;
    }

    video[poster] {
        width: 100%;
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 100% !important;
    }

    #module {
        background-color: #FFFFFF;
    }

    #module .module-containt {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 50px #0000001F;
        margin-top: 20px !important;
        border-radius: 20px;
    }
} */


@media screen and (max-width:320px) {
    #module h6 {
        font: normal normal normal 20px/24px Inter;

    }

    #module h3 {
        font: normal normal bold 34px/42px Inter;
    }

    #module p {

        font: normal normal normal 20px/22px Inter;

    }

    #module .module-details ul {
        text-align: left;
        font: normal normal normal 14px/20px Inter;
    }
    #module video {
        left: 0px;
    }
}