.trustedby {
    text-align: center;
    background: #F5F7FA 0% 0% no-repeat padding-box;
}

.trustedby h1 {
    font: normal normal bold 56px/85px inter;

    color: #323232;
    opacity: 1;
    padding-top: 60px;
    padding-bottom: 30px;
}

.trustedby .imagecontent {
    justify-content: space-evenly;
    margin-bottom: 55px;
    flex-wrap: wrap;
}

.trustedby img {
    width: 170px;
    padding: 0;
    border-radius: 12px;
    filter: grayscale(100%);
    opacity: 0.6;
}

.trustedby img:hover {
    filter: grayscale(0%);
    opacity: 1;
}

.trustedby button {
    font: normal normal 600 24px/35px inter;
    font-weight: 75px;
    /* font: normal normal medium 24px/29px Inter; */
    border: none;
    color: #E5E5E5;
    background: transparent linear-gradient(180deg, #0094D8 0%, #0094D8 100%) 0% 0% no-repeat padding-box;
    border-radius: 12px;
    margin-bottom: 55px;
    padding: 15px 50px 15px 45px;
    transition: transform 0.2s
}

.trustedby button:hover {
    transform: scale(1.1);
}

.imagecontainer1 {
    justify-content: space-evenly;
}

.imagecontainer2 {
    justify-content: space-evenly;
}

@media(max-width:604px){
    .trustedby .imagecontent{
        margin-left:0px;
        
    }
    .trustedby img {
        width: 140px;
    }
    /* .trustedby .row{
        margin-right:0px;
    } */
}

@media (max-width:768px) {
    .imagecontent {
        display: block;
    }

    .imagecontent img {
        display: block;
        /* margin:  auto; */
    }

    .trustedby img {
        width: 50%;
        max-width: 240px;

    }

    .trustedby h1 {
        font: normal normal bold 40px/85px inter;

    }
}



@media (max-width: 320px) {
    .trustedby button {
        font: normal normal 600 20px/35px inter;
        width: 275px;
        height: 55px;
        padding: 12px 50px 15px 45px;
    }
    .trustedby h1 {
        font: normal normal bold 36px/85px inter;
    }
    .imagecontainer1 {
        padding: 0px;
    }
    .imagecontainer2 {
        padding: 0px;
    }
}

