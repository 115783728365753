.navitem{
    top: 17px;
    left: 94px;
    right: 106;
    bottom: 52;
}
.navbar>.container{
    padding: 0px;
    margin: 0px 16px;
}


.nav-img{
    top: 29px;
    left: 115px;
    width: 40px;
    height: 43px;
    opacity: 1;
}
#submit-btn{
    top: 29px;
    left: 1104px;
    /* width: 100%; */
    height: 48px;
    inline-size: 220px;
    font-size: medium;
    background: var(--unnamed-color-0094d8) 0% 0% no-repeat padding-box;
    background: #0094D8 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    color: #FFFFFF;
    margin-left: 25px;
    margin-right: 12px;
}
#submit-btn:hover{
    transform: scale(1.1);
}
#nav-brand-context{
    top: 41px;
    left: 893px;
    width: 144px;
    height: 21px;
    /* font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-18)/21px var(--unnamed-font-family-inter); */
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 600 18px/30px Inter;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
#nav-dropdown{
    top: 41px;
    left: 893px;
    width: 144px;
    height: 21px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-18)/21px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 400 18px/30px Inter;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;

}
.navbar-content {
    width: 74%;
    position: fixed;
    z-index: 1030;
    background-color: rgba(46,46,46,0.7);
    border-radius: 12px;
    height: 66px; 
    bottom: 52;
    opacity: 1; 
    left: 50%;
    transform: translate(-50%, 0);
    top: 17px;
}

  .login a{
    text-decoration: none;
    color: white; 
    font-size:medium;
    /* font: var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-18)/21px var(--unnamed-font-family-inter); */
    font: normal normal 600 18px/21px Inter;
    font-weight: 75px;
    
    
  }

.navbar-content li{
    list-style: none;
}


.navbar-content .nav-item {
    /* margin-right: 20px; */
    font: normal normal 500 17px/30px inter;
    
    color: #323232;
    opacity: 1;
}

.navbar-content img {

    width: 40px;
    height: 42px;
    opacity: 1;
}
.navbar-content .select-head{
    width: auto;
    border: none;
    color: #2532C9;
}
.navbar-content .select-head:active ,.navbar-content .select-head:focus{
    box-shadow: none;
}

.navbar-content .login {
    background: #F6F7FF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    height: 44px;
    opacity: 1;
    color: #2532C9;
    font: normal normal 400 24px/35px inter;
    border: none;
    transition: transform .2s;
}



.navbar-content .login:focus {
    outline: none;
    box-shadow: none;
}
.smchooseindustry{
    display: none;
}

@media(max-width:991px){
    .chooseindustry{
        font-size: 16px;
    }
    .navbar-content .nav-item {
        font: normal normal 500 16px/30px inter;
        padding: 10px;
    }

.navbar-content .nav-item:first-child {
    padding-top: 0;
}
    .navbar-content .login {
        font: normal normal 400 17px/35px inter;
    }
    .navbar-collapse .navbar-nav {
        background-color:rgba(0, 0, 0, 0.8);
        border-radius: 3px;
        padding:12px;
    }
    .nav-link{
        padding:0 ;
    }
    .dropdown-menu, .dropdown-item{
        color: #FFFFFF !important;
        background-color: transparent;
    }
}
@media (max-width:768px){
    .navbar>.container {
        padding: 0px;
        margin: 0 14px 1px 10px;
    }
}
@media (max-width:520px) {
    .smchooseindustry{
        display: block !important;
    }

   .navbar-content .container {
    margin-top: 0px;
    margin-bottom: 0px;
}

}

@media (max-width:425px){
    .navbar-content {
        width:100%;
        position: absolute;
    }
}
@media (max-width:390px) {
    .navbar-content .login a {
        font: normal normal 400 16px/35px inter ;
    }
   
}
@media (max-width:375px){
    .navbar-content {
        width:100%;
        position: absolute;
    }
}

