.expert-form {
    text-align: center;
    background-color: #2E2E2E;
    border-radius: 20px;
    padding-top: 60px;
    padding-bottom: 100px;
    margin-bottom: 100px;
    margin-top: 130px;
}

.expert-form h1 {
    font: normal normal bold 48px/72px inter;

    color: #FFFFFF;
    opacity: 1;
}

.expert-form p {
    font: normal normal normal 24px/32px inter;

    color: #C6C6C6;
    opacity: 1;
    margin-bottom: 50px;
}

.expert-form lottie-player {
    margin: 0 auto;
}

.expert-form .form-group input {
    width: 525px;
    height: 56px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    margin: auto;
    margin-bottom: 30px;
    font: normal normal normal 20px/30px inter;

    color: #707070;
}

.expert-form button {
    font: normal normal 600 24px/35px inter;

    color: #E5E5E5;
    background: #0094D8 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    padding: 15px 50px 15px 45px;
    font: normal normal 600 24px/35px inter;
    color: #FFFFFF;
    margin-top: 30px;
    transition: transform .2s;
}

.expert-form button:hover {
    transform: scale(1.1);
    color: white;
}

.response h2 {
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.response p {
    color: #C6C6C6;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width:768px) {
    .expert-form .expertplayer {
        width: 100% !important;
        height: 100% !important;
    }
    .response h2 {
        font-size: 40px;
    }
    
    .response p {
        font-size: 22px;
    }
}

@media (max-width:550px) {
    .expert-form .form-group input {
        width: 80%;
    }

    .expert-form button {
        font-size: 20px;
    }

    .expert-form h1 {
        font: normal normal bold 35px/45px inter;
    }

    .expert-form p {
        font: normal normal normal 20px/32px Segoe UI;
    }
    .response p {
        font-family: Poppins;

    }
}

@media (max-width:375) {
    .expert-form p {
        font: normal normal normal 18px/21px Inter;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/21px var(--unnamed-font-family-inter);
        color: #C6C6C6;

        opacity: 1;
        letter-spacing: var(--unnamed-character-spacing-0);
        margin-bottom: 50px;
    }
}


@media screen and (max-width:320px) {
    .expert-form .form-group input {
        width: 85%;
        height: 38px;
    }

    .expert-form h1 {
        font: normal normal bold 30px/40px inter;
    }

    .expert-form p {
        font: normal normal normal 18px/30px inter;
    }

    .expert-form button {

        padding: 10px 50px 15px 45px;
        width: 275px;
        height: 55px;

    }

}