.background-video{
    width: 100%;
    background-color: #2E2E2E;
    position: relative;
    
}
.background-video1{
    display: none;
    width: 100%;
    background-color: #2E2E2E;
    position: relative;
}
/* .background-video .video-title{
    position: absolute;
} */
.background-video h2{
    margin-bottom: 20px;
    font: normal normal bold 56px/85px inter;
letter-spacing: 0px;
color: #FFFFFF;
}
.background-video p{
   
    font: normal normal normal 24px/32px inter;
    letter-spacing: 0px;
    color: #C5C5C5;
}
.background-video video{
    text-align: center;
    width: 100%;
    object-fit:cover;
    opacity: 0.1;
    top: 5250px;
    left: 0px;
    height: 526px;
    /* UI Properties */
    opacity: 0.2;

   
}
.background-video button {
    text-align: center;
    font: normal normal 600 24px/35px inter;
    font-weight: 75px;
    background:#0094D8 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    padding: 15px 50px 15px 45px;
    font: normal normal 600 24px/35px inter;
    color:#FFFFFF;
    margin-top: 30px;
    transition: transform 0.2s;
}
.background-video button:hover{
    transform: scale(1.1);

}
.video-text {
    position: absolute;
    left: 0;
    text-align: center;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
}
@media (max-width:836px){
  
 
    .background-video h2{
        margin-bottom: 20px;
        font: normal normal bold 28px/40px inter;
   
    }
    .background-video p{
       
        font: normal normal normal 16px/32px inter;
    letter-spacing: 0px;
    color: #C5C5C5;
    }
    
    .background-video button {
        padding: 15px 50px 15px 45px;
        font: normal normal 600 20px/35px inter;
        margin-top: 20px;
    }
   
}
@media (max-width:550px){
    .background-video{
        display:none;
    }
    .background-video1{
        display: block;
    }
    .background-video1 video{
        text-align: center;
        width: 100%;
        opacity: 0.1;
    }
   .background-video1 .video-text {
        position: absolute;
        left: 0;
        text-align: center;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        color: #FFFFFF;
    }
    .background-video1 h2{
        margin-bottom: 10px;
        font: normal normal bold 30px/40px inter;
   
    }
    .background-video1 p{
       
        font: normal normal normal 18px/32px inter;
    letter-spacing: 0px;
    color: #C5C5C5;
    }
    
    .background-video1 button {
        font: normal normal 600 20px/35px inter;
    color: #E5E5E5;
    background:#0094D8 0% 0% no-repeat padding-box;
    border-radius: 12px;
    color:#E5E5E5;
    }
}
@media screen and (max-width:320px){
    background-video1 h2 {
        margin-bottom: 10px;
        font: normal normal bold 24px/34px inter;
    }
    .background-video1 p {
        font: normal normal normal 16px/30px inter;
    }
    
}


