.backgroundleft-image1 {
    width: 100%;
    padding: 12px;
    background-repeat: no-repeat;
    background-image: url(../../images/back.jpg);
    background-size: 100% 650px;
    background-position-y: 250px;
    overflow-x: hidden;
}

.faq {
    text-align: center;
    margin-bottom: 100px;
}

.faq .accordion {
    width: 90%;
    margin: auto;
}

.faq h1 {
    font: normal normal bold 56px/85px inter;

    color: #323232;
    opacity: 1;
    margin-bottom: 50px;
}

.faq .accordion-body {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px #9b83fe29; */
    border-radius: 0px 0px 6px 6px;
    font: normal normal normal 16px/22px inter;
    padding: 20px;
    text-align: left;
    color: #323232;
    opacity: 1;
}
.faq .accordion-header{
    background: #F5F7FA 0% 0% no-repeat padding-box;
}
.faq .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(../../images/downArrow.svg);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    transform: rotate(-90deg);
}

.faq .accordion-button:not(.collapsed)::after {
    background-image: url(../../images/downArrow.svg);
    transform: rotate(0deg);

}

.faq button.accordion-button.collapsed {
    height: 96px;    
    border-radius: 6px 6px 0px 0px;
    text-align: left;
    font: normal normal normal 18px/32px inter;
    color: #323232;
    opacity: 1;
    width:100%;
}

.faq .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    text-align: left;
    font: normal normal normal 18px/32px inter;
    
    color: #323232;
    opacity: 1;
}
.faq .accordion-flush .accordion-item{
    border: none;
    margin-bottom: 4px;
}

.faq button.accordion-button {
    height: 96px;
    background: #F5F6FB 0% 0% no-repeat padding-box;
    border-radius: 6px 6px 0px 0px;

    opacity: 1;
}

.faq .accordion-flush .accordion-item .accordion-button:focus {
    box-shadow: none;
}

.FAQsButtons {
    /* padding-left: 20rem; */
    /* padding-right: 20rem; */
    padding-top: 0px;
    box-sizing: border-box;

}

.FAQsButtons button {
    font-size: 18px;
    background-color: white;
    border: none;
    width: 174px;
    height: 44px;
    padding-left: 49px;
    padding-right: 49px;
    padding-top: 12px;
    padding-bottom: 11px;
    position: relative;
    top: 1rem;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-inter);
    font: normal normal 600 18px/24px inter;
    opacity: 0.5;
}                                                                                                                                                                                                           

.FAQsButtons .active {
    background: #D5EDF9 0% 0% no-repeat padding-box;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
}
@media(max-width:1440px){
       .FAQsButtons {
        padding-left: 10% !important;
        padding-right: 10% !important;
        width: 100%;
        height: 68px;
       
    }
    .FAQsButtons button {
        width: 30%;
        height: 30px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 18px/20px Inter;
        opacity: 0.5;
    }
}
@media(max-width:1024px){
    .FAQsButtons {
        padding-left: 10% !important;
        padding-right: 10% !important;
        width: 100%;
        height: 68px;
       
    }
    .FAQsButtons button {
        width: 30%;
        height: 30px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 16px/18px Inter;
        opacity: 0.5;
    }
}

@media (max-width:550px) {
    .faq h1 {
        font: normal normal bold 40px/55px inter;
    }

    .faq .accordion-flush .accordion-item .accordion-button {
        font: normal normal normal 16px/22px inter;
        width: 100%;
    }
    .accordion-flush .accordion-item {
      
        margin-right: -8px;
    }
    .faq .accordion-body {
        font: normal normal normal 12px/22px inter;
        padding: 20px;
    }
    .FAQsButtons {
        padding-left: 10% !important;
        padding-right: 10% !important;
        width: 100%;
        height: 68px;
       
    }
    .FAQsButtons button {
        width: 30%;
        height: 30px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 12px/18px Inter;
        opacity: 0.5;
    }
}

@media(max-width:425px){
    .FAQsButtons {
        padding-left: 10% !important;
        padding-right: 10% !important;
        width: 100%;
        height: 68px;
       
    }
    /* .FAQsButtons button {
        font: normal normal 600 14px/18px Inter;
    } */
    /* .faq .accordion-body {
        font-size: 16px;
    } */
}
@media(max-width:425px) {

    .faq-container {
        padding-left: 2px!important;
        padding-right: 0px !important;
    }

    /* .faq .accordion-body {
        font: normal normal normal 12px/22px inter;
        padding: 20px;
    } */
    .faq .accordion-body {
        font-size:14px;
    }

    /* .FAQsButtons {
        padding-left: 20%;
        padding-right: 20%;
    } */

    /* .FAQsButtons button {
        width: 30%;
        height: 30px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 12px/18px Inter;
        opacity: 0.5;
    } */
    /* .FAQsButtons button {
        width: 94px;
        height: 32px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 16px/24px Inter;
        opacity: 0.5;
    } */

    .faq button.accordion-button.collapsed {
        height: 96px;
        font: normal normal normal 14px/32px inter;
        position: relative;
        /* top: 1rem; */
        /* background-color: blue; */
        /* right:0.3rem; */
    }

    .faq .accordion {
        width: 100%;
    }

    .FAQsButtons .active {
        padding-left: 15px;
        color: #626262;
    }

    .backgroundleft-image1 {
        padding: 0px;
    }
}


/* @media(max-width:374px){
    .FAQsButtons {
        padding-left: 0rem;
        padding-right: 0px !important;
    }
    .FAQsButtons .active {
        padding-left: 12px;
        color: #626262;
    }
    .FAQsButtons button {
        width: 80px;
        height: 32px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 14px/24px Inter;
        opacity: 0.5;
    }
} */
@media screen and (max-width:320px){
    .faq button.accordion-button.collapsed {
      
        font: normal normal normal 14px/32px inter;

        padding-right: 65px;
    }
    .faq-container {
        padding-left: 2px!important;
        padding-right: 0px !important;
    }

    .faq .accordion-body {
        font: normal normal normal 14px/22px inter;
        padding: 20px;
    }


    .FAQsButtons {
        padding-left: 20%;
        padding-right: 20%;


    }

    .FAQsButtons button {
        width: 30%;
        height: 30px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 12px/18px Inter;
        opacity: 0.5;
    }
    /* .FAQsButtons button {
        width: 94px;
        height: 32px;
        padding-left: 13px;
        padding-right: 19px;
        padding-top: 8px;
        padding-bottom: 11px;
        font: normal normal 600 16px/24px Inter;
        opacity: 0.5;
    } */

    .faq button.accordion-button.collapsed {
        height: 96px;
        font: normal normal normal 14px/32px inter;
        position: relative;
        /* top: 1rem; */
        /* background-color: blue; */
        right:0.3rem;
    }

    .faq .accordion {
        width: 100%;
    }

    .FAQsButtons .active {
        padding-left: 15px;
        color: #626262;
    }

    .backgroundleft-image1 {
        padding: 0px;
    }
}
