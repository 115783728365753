#unique p {
    text-align: left;
    font: normal normal normal 22px/33px inter;
    color: #707070;

}

#unique h2 {
    text-align: center;
    font: normal normal bold 56px/85px inter;
    color: #323232;
    /* margin-top: 50px; */
    margin-bottom: 30px;
}
@media(min-width:1440px) {
    #unique p {
        /* padding: 100px; */
        margin: 0;
 
    }
}
@media(max-width:1199px){
    #unique p {
        font: normal normal normal 17px/25px inter;
         /* padding-top: 90px; */
         margin: 0;
    }
}
@media(max-width:991px){
    #unique img{
        width: 75%;
        margin: auto;
        height: 50%;
    }
    #unique p{
        /* padding-top: 50px; */
        margin: 0;
    }
    #unique .parapadding{
        padding-top: 30px;
    }
}

@media(max-width:560px){
    #unique h2 {
        font: normal normal bold 40px/40px inter;
    }
    #unique p{
       text-align: center;
        font: normal normal normal 16px/20px inter;
        /* padding: 30px 10px; */
        margin: 0;
    } 
}
@media(max-width:420px){
    #unique h2 {
        font: normal normal bold 30px/40px inter;
    }
}
@media(max-width:375px){
    #unique h2 {
        font: normal normal bold 40px/49px Inter;
    }
    #unique p{
        text-align: left;
        font: normal normal normal 16px/20px Inter;
        /* padding: 30px 10px; */
        margin: 0;
     } 
     .uniquecontaint{
        width: 100%;
        margin:auto;
     }
     #unique img{
        width: 100%;
        height: 50%;
     }
     
}