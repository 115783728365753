.ResponsiveImage{
    display: none;
}
.btn-lg a {
    text-decoration: none;
    color: white;
    border: none;
    font: normal normal medium 24px/35px inter;

}

/* .row {
    --bs-gutter-x: 2rem;
} */


.banner_section {
    text-align: center;
    margin-bottom: 10.60%;
}

.banner {
    background-image: url(../../images/bannerbackground.png);
    padding-left: 0;
    padding-right: 0;
    background-size: 100%;
    overflow: hidden;
    margin: -190px 0 0;
    background-position-y: -80px;
    background-repeat: no-repeat;
    /* margin-bottom: 225px; */
}


.banner_section button {
    font: normal normal 600 24px/35px inter;
    color: #E5E5E5;
    background-color: #0094D8;
    border-radius: 12px;
    opacity: 1;
    float: left;
    width: 288px;
    height: 72px;
    border: none;
    transition: transform 0.2s;
}



.banner_section button:focus {
    box-shadow: none;
}

.banner_section button:hover {
    transform: scale(1.1);

}



.banner_images_main {
    display: inline-flex;
}

.col-md-5.banner_main {
    margin: 272px 0 0;
}

.banner-right-images {
    position: relative;
    margin: 190px 0 0;
    right: -32%;
}

.banner_images_one {
    margin: 0 38px 0 0;
    padding: 0;
    align-items: flex-end;
    transform: rotate(-20deg);
}

/* .banner_images_one img {
    width: -webkit-fill-available;
} */


.banner-right-images .image1,
.banner-right-images .image2,
.banner-right-images .image3,
.banner-right-images .image4,
.banner-right-images .image5 {
    transition: all .3s ease-in-out;
}

.banner-right-images:hover .image1 {
    transform: translate(-30px, -30px);

}

.banner_images_one img {
    width: 100%;
}

.banner_images_one {
    margin: 0 68px 0 -40px;
    padding: 0;
    align-items: flex-end;
    transform: rotate(-20deg);
}

.banner_images_two .image3 {
    width: 100% !important;
    /* margin-top: 15px; */
    position: relative;
    right: 32px;

}

.banner_images_two .image2 {
    height: 275px;
    /* margin-top: 30px; */

    right: 6%;
    margin-top: -14px;
    position: relative;

}

.banner_images_two .image4 {
    height: 275px;
    margin-bottom: 10px;
    position: relative;
    bottom: 10px;
}

.banner-right-images :hover .image5 {
    transform: translate(-30px, 25px);

}


.banner-right-images :hover .image2 {
    transform: translate(-20px, -50px);
}

.banner-right-images :hover .image4 {
    transform: translate(30px, -20px);
}

.banner-right-images :hover .image3 {
    transform: translate(10px, 20px);
}



.banner_images_two {
    padding: 0;
    margin: -35px 0 0 0px;
    transform: rotate(-20deg);
}

.banner_images_one img {
    margin: 0;
    padding: 0;
}


.banner_title_main {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    margin-left: 0;
}

.banner_title {
    margin-left: 0;
    padding: 0;
    width: 667px;
    /* min-height: 102px; */
    text-align: left;
}

.banner_title h4 {
    font: var(--unnamed-font-style-normal) normal bold 20px/20px inter;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal bold 42px/44px inter;
    color: #FFFFFF;
    font-size: 50px;
    line-height: 56px;
}

.banner_text p {
    width: 533px;
    min-height: 57px;
    text-align: left;
    color: #FFFFFF;
    font-size: 20px;
}

.bannerlinesimg {
    width: 100%;
    height: 391px;
    position: absolute;
    margin: inherit;
    z-index: -1;
    margin: -339px 0 0;
}

@media(max-width:1440px) {
    
    .banner {
        background-size: 122%;
    }

    .banner-right-images {
        position: relative;
        margin: 235px 0 0;
        right: -22%;
        width: 51%;
    }

    .banner_images_two .image2 {
        right: 9%;
        height: 246px;
    }

    .banner_images_two .image3 {
        right: 11%;
        bottom: 274px;
    }

    .banner_images_two .image4 {
        width: auto;
        right: -42%;
        bottom: 274px;
    }
    .banner_title h4{
        font-size: 45px;
    }
}
@media(max-width:1399px){
    .banner-right-images {
        right: -28%;
    }
}
@media(max-width:1280px){
    .banner-right-images {
        right: -28%;
    }
}
@media screen and (min-width:1024px) and (max-width:1200px) {
    .banner_title h4{
        font-size: 35px;
    }
    .banner_title {
        width: 550px;
        margin-left:20px;
    }
    .banner_text{
        margin-left:20px;
    }
    .btn-lg{
        margin-left:20px;
    }


}
@media screen and (min-width:992px) and (max-width:1024px) {

    .banner {
        background-size: 137%;
    }

    .banner_title {
        width: 138%;
    }

    .banner_title h4 {
        font: normal normal bold 40px/50px inter;
    }

    .banner-right-images {
        position: relative;
        margin: 248px 0 0;
        right: -24%;
    }

    .banner_images_one img {
        width: 150px;
    }

    .banner_images_two .image2 {
        height: 150px;
        width: auto;
        right: 15%;
    }

    .banner_images_two .image3 {
        bottom: 38px;
        right: 18%;
    }

    .banner_images_two .image4 {
        height: 175px;
        width: auto;
        right: 14%;
        bottom: 37px;
    }

    .banner_section button {
        width: 250px;
        height: 55px;

    }


}

@media screen and (min-width:768px) and (max-width:991px) {


    .banner_title_main {
        margin-top: 70px;
    }

    .banner {
        /* background-size: 115% 92%; */
        background-size: 106% 94%;
    }

    .col-md-5.banner_main {
        margin: 112px 0 0;
        width: 60%;
    }

    .banner_title {
        width: 100%;

    }

    .banner_text p {
        width: 90%;
        font-size: 16px;
    }

    .banner_title h4 {
        font: normal normal bold 24px/34px inter;
    }

    .banner_section button {
        width: 70%;
        height: 50px;
    }

    .banner-right-images {
        position: relative;
        margin: -242px 0 0;
        right: -66%;
        width: 50%;
        

    }
    .ResponsiveImage{
        display: none;
        width: 40%;
        margin-top: 117px;
        
    }

    .rImage {
        width: 160%;
        margin-left: -34px;
    }

    .banner_images_two .image2 {
        height: 150px;
        margin-top: 58px;
        width: auto;
        position: relative;
        right: 26%;
    }

    .banner_images_two {
        margin: -104px 0 0 0px;
    }

    .banner_images_two .image4 {
        width: auto;
        height: 175px;
        position: relative;
        bottom: 175px;
        left: 32%;
    }

    .banner_images_two .image3 {
        right: 33%;
        bottom: 178px;
    }

}

@media(max-width:767px) {

    .banner_title_main {
        margin-top: -34px;
    }

    .col-md-5.banner_main {
        width: 100%;
        margin: 203px 0 0;
    }

    .banner-container {
        font: normal normal bold 26px/32px Inter;
    }

    .banner {
        padding-left: unset !important;
        background-size: 200% 83%;
        overflow-x: hidden;
        background-position-y: 0px;
        margin-bottom: -145px;
    }

    .banner_title {

        width: 100%;
        position: relative;
    }


    .banner_title h4 {
        font: normal normal bold 30px/42px Inter;
    }

    .banner_text p {
        width: 100%;
        font: normal normal normal 25px/30px Inter;
    }

    .banner-right-images {
        display:none;
        
    }
    .ResponsiveImage{
        display: block;
        width: 100%;
        float: right;
        margin-bottom: 200px;
        
    }

    .rImage{
        width:100%;
        position: relative;
        left: 15%;
    }
   

}

@media(max-width: 550px) {
    .banner-right-images {
        display: none;
    } 
    .banner {
        padding-left: unset !important;
        background-size: 198% 80%;
    }
    .ResponsiveImage{
        display: block;
        width: 100%;
        margin-left: 100px;
        margin-bottom: 220px;
        
    }

    .rImage{
        width:100%;
    }
}


@media (max-width:425px) {

    .banner_text p {
        width: 100%;
        font: normal normal normal 18px/30px Inter;
    }
    .banner {
        background-size: 162% 72%;
        margin-bottom: -275px;
    }
    .banner_section button {
        width: 100%;
        height:50px;
        font: normal normal 400 12px/18px inter;
    }

    .banner-right-images {
     
        display: none;
    } 
    .ResponsiveImage{
        display: block;
        width: 100%;
        margin-left: 100px;
        margin-bottom: 368px;
        
    }

    .rImage{
        width:100%;
    }

}

@media (max-width: 375px) {

    .banner-container {
        font: normal normal bold 26px/32px Inter;
    }

    .banner {
        padding-left: unset !important;
        background-size: 160% 82%;
        overflow-x: hidden;
        background-position-y: 0px;
        margin-bottom: -145px;
    }

    .banner_title {
        width: 100% !important;
        height: 111px;
    }

    .banner_title h4 {
        font: normal normal bold 26px/32px Inter !important;
    }

    .banner_text p {
        width: 100% !important;

        font: normal normal normal 16px/24px Inter;
    }

    .col-md-5.banner_main {
        margin: 203px 0 0;
    }

   
    .banner_section button {
        width: 100% !important;
        height:44px;
        font: normal normal 400 12px/18px inter;

    }
    .banner-right-images {
        position: relative;
        margin: 60px 0 0;
        right: -26% !important;
       
        left: 45% !important;
        display:none;
    }
    .ResponsiveImage{
        display: block;
        width: 100%;
        margin-left: 56px;
        margin-bottom: 165px;
        
    }

    .rImage{
        width:100%;
    }

}

@media (max-width:320px) {

    .banner {
    
        background-size: 218% 79%;
    }
    .banner-right-images {
        margin: 24px 0 68px;
        left: 18%;
        display:none;
    }

    .banner_title {
        margin-top: 50px;
    }

    .banner_title h4 {
        font: normal normal bold 22px/24px Inter !important;
    }

    .banner_text p {
        width: 100%;
        margin-top: -22px;
        font: normal normal normal 14px/20px Inter;
    }


    
}