.img {
    top: 8775px;
left: 115px;
width: 94px;
height: 94px;
}
footer {
    background: #F5F7FA 0% 0% no-repeat padding-box;
    opacity: 1;
    top: 8716px;
    left: 0px;
    /* width: 1440px; */
    /* height: 400px; */
    /* UI Properties */
    opacity: 1;
}

.footer-container {
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto;
}

.footer-container h2 {
    font: normal normal bold 30px/46px inter;
    
    color: #0094D8;
    opacity: 1;
}

.footer-container p {
    font: normal normal normal 18px/24px inter;

    color: #323232;
    opacity: 1;
    padding-right: 10px;
}

.footer-container li a {
    font: normal normal 500 18px/44px inter;
    
    color: #323232;
    opacity: 1;
    text-decoration: none;
}

/* .footer-container li {
    list-style: none;
} */

.footer-container h4 {
    font: normal normal 500 22px/31px inter;
    
    color: #323232;
    opacity: 1;
}
.footer-container li{
    list-style: none;
}

.footer-container h6 {
    font: normal normal 500 18px/69px inter;
    
    color: #323232;
    opacity: 1;
}

.footer-container a {
    margin-right: 10px;
}

.footer-container input {
    height: 55px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
}

.footer-container ::placeholder {
    font: normal normal normal 20px/30px inter;
    
    color: #707070;
    opacity: 1;
}

.footer-container .download {
    width: 144px;
    height: 56px;
    background: transparent linear-gradient(180deg, #1E38EE 0%, #302684 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: white;
}
@media (max-width:1199px){
    .footer-container .download {
       margin-top: 10px;
    }
    .footer-container h4 {
        font: normal normal 500 18px/40px inter;
    }

}
@media (max-width:991px){
    .footer-container form {
        flex-wrap: wrap;
    }
    .footer-container h4 {
        font: normal normal 500 18px/30px inter;
    }

.footer-container  ::placeholder{
    font-size: 14px;
}
.footer-container p {
    font: normal normal normal 14px/24px inter;
}
.footer-container li a {
   font-size: 14px;
}
}
@media (max-width:550px){
    .footer-container {
        flex-direction: column;
    }
}
@media (max-width:375px){
    .footer-container {
        padding-left:0px !important;
    }
}