#business {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 20px;
}

#business h2 {
    font: normal normal bold 56px/85px inter;
    
    color: #323232;
}

#business p {
    font: normal normal normal 24px/32px inter;
    
    color: #626262;
    margin-bottom: 30px;
}

#business .card {
    
   
    border: none;
    
    margin-top: 40px;
    margin-bottom: 40px;
    /* //opacity: 0.08; */
}

#business img {
    width: 100px;
    position: relative;
    margin: auto;
    margin-top: -42px;
}

#business .card-body {
    margin-top: -52px;
    background: #F5F7FA 0% 0% no-repeat padding-box;
    
    /* background: transparent linear-gradient(180deg, rgba(162, 174, 255, 0.08) 0%, rgba(27, 0, 255, 0.08) 100%) 0% 0% no-repeat padding-box; */
    /* background: transparent linear-gradient(180deg, rgba(245, 247, 250, 0.08) 0%, rgba(245, 247, 250, 0.08) 100%) 0% 0% no-repeat padding-box; */
    min-height: 159px;
    padding-top: 52px;
    border-radius: 12px;
}

#business .card-title {

    font: normal normal 600 18px/32px inter;
    
    color: #323232;
}

#business .card-text {

    font: normal normal normal 16px/24px inter;
    
    color: #626262;
}
#business .cardimg-hover:hover  {
    transition: 0.3s;
    transform: translate(0, -10px);

  }
  #business .card-body:hover {
    /* box-shadow: -3px 6px 22px -11px rgba(0,0,0,0.75); */
    box-shadow:2px 2px 24px -14px rgba(0,0,0,0.75)
}
#business .cardimg-hover:hover img {
    transition: 0.3s;
    transform: translate(0, -10px);
  }
@media(max-width:550px){
    #business {
        margin-top: 50px;
    }
    #business .card-title {
        font: normal normal 600 16px/22px inter;
    }
    #business .card-text {
        font: normal normal normal 14px/20px inter;
    }
    #business h2 {
        font: normal normal bold 40px/40px inter;
     
    }
    #business p {
        font: normal normal normal 18px/32px inter;
    }
    
}
@media(max-width:420px){
    #business h2 {
        font: normal normal bold 30px/40px inter;
     
    }
    #business p {
        font: normal normal normal 16px/32px inter;
        font: normal normal normal 18px/32px Inter;
    }
    
}


@media (max-width: 375px){
#business {
    padding-left: 13px !important;
    /* padding-right: 0px !important; */
}
#business h2 {
    font: normal normal bold 40px/49px Inter;
 
}
#business p {
    font: normal normal normal 18px/32px Inter;
}
.justify-content-evenly{
    width:100%;
    margin:auto;
}
}