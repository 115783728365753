
.industries{
    margin-top: 100px;
}
.industriesimgcontainer{
    position: relative;
    margin: 15px 12px;
    padding: 0px;

}
.industries h2{
    text-align: center;
    font: normal normal bold 56px/85px inter;
    letter-spacing: 0px;
    color: #323232;
    margin-bottom: 50px;
}

.overlay{
    position: absolute;
    height: 100%;
    z-index: 3;
    background: linear-gradient(0deg, rgb(9, 9, 10) 0%, rgb(12, 12, 14) 100%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    border-radius: 21px;
  
}
.industriesimg{
    width: 100%;
    height: 100%;
    filter: grayscale(90%);
}
.industriesimgcontainer .industriesheading{
    /* width: 50%; */
  margin: auto;
  position: absolute;
  font: normal normal bold 28px/37px inter;
  top: 30%; left: 50%;
  -webkit-transform: translate(-20%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
          z-index: 5;
          color: #fff;
          text-align: center;
}
.industriesimgcontainer .fa-angle-right{
    display:none;
}
.industriesimgcontainer:hover .fa-angle-right{
    display:block !important;
}
.industriesimgcontainer:hover{
    transition: 0.3s;
    transform: translate(0, -10px);
}
.industriesimgcontainer:hover .overlay{
    opacity: 0.6;
}

.industriesimgcontainer:hover .industriesimg{
    filter: grayscale(0%);
}
.industriesheading h4{
    font-weight: 600;
}
@media(min-width:992px){
    .industriesimgcontainer{
        width: 22%;
    } 
    .industrycontainer{
        margin: auto;
    }
}
@media(max-width:991px){
    .industriesimgcontainer{
        margin: 10px 10px;
        width: 45%;

    } 
    .industrycontainer{
        margin: auto;
    }
}
@media(max-width:448px){
    .industriesimgcontainer{
        margin: 10px auto;
        width: 70%;
        height: 300px;
     
    } 
    .industrycontainer{
        margin: auto;
    }
    .industries h2 {
        font: normal normal bold 35px/17px inter;}
}
@media(max-width:375px){
    .industrycontainer{
        padding-left: 50px !important;
            padding-right: 25px !important;
     
    } 

.industriesimgcontainer {
    height: 330px;
    width:276px;
}
  
}
@media (max-width: 320px){
.industrycontainer {
    padding-left: 28px !important;

}
}

@media screen and (max-width: 767px){
    .industriesimgcontainer {
        width: 100%;
        height: auto;
    }
    .module-containt {
        min-height: auto;
        width: 92%;
        margin: 0 auto;
    }
    .faq h1 {
        margin-bottom: 10px;
    }
    .expert-form {
        padding-top: 30px;
        padding-bottom: 50px;
        margin-bottom: 50px;
        margin-top: 10px;
    }
    .main {
        gap: 2rem;
    }
}